import React from "react";

export const QuoteIcon = () => {
  return (
    <svg width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.734 0.099997C21.634 3.2 18.234 8.2 18.234 13.3V21.6H30.634V11.5H26.134C26.434 7.5 28.334 5.1 30.434 3L26.734 0.099997ZM8.83398 0.099997C3.73398 3.2 0.333985 8.2 0.333985 13.3V21.6H12.734V11.5H8.23398C8.53399 7.5 10.434 5.1 12.534 3L8.83398 0.099997Z"
        fill="#94514E"
      />
    </svg>
  );
};
