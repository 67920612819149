import React, { FunctionComponent } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { ImageInterface, PortableText } from "../../types/SanityTypes";
import RichText from "../RichText";
import { QuoteIcon } from "../../images/icons/quoteIcon";
import Section from "../Section";
import "./styles.scss";
import { IMAGE_SIZES } from "../../constants";

export interface QuoteBlockInterface {
  isAuthor: boolean;
  isQuoteIcon: boolean;
  extendedAuthorInfo: boolean;
  author?: {
    _rawImage?: ImageInterface;
    image: ImageInterface;
    name: string;
    designation?: string;
  };
  _rawQuoteLines: PortableText;
  bgColor?: {
    bgColor?: {
      title: string;
      value: string;
    };
  };
}

const QuoteBlock: FunctionComponent<QuoteBlockInterface> = ({
  isAuthor,
  author,
  _rawQuoteLines,
  bgColor,
  isQuoteIcon,
  extendedAuthorInfo
}) => {
  const sanityImage = author?.image;

  return (
    <Section
      sectionClassName={`quote-block ${author ? "quote-block--with-author" : ""}`}
      theme={bgColor?.bgColor?.title}
    >
      <div className="quote-block-text">
        {isQuoteIcon && <QuoteIcon />}
        <RichText data={_rawQuoteLines} />
      </div>
      {isAuthor && author && (
        <div className="quote-block-author">
          {extendedAuthorInfo && sanityImage && (
            <div className="quote-block-author__image">
              <SanityImage
                {...sanityImage}
                width={74}
                height={91}
                htmlWidth={74}
                htmlHeight={91}
                className="img-fluid"
                sizes={IMAGE_SIZES.SMALL_SQUARE}
              />
            </div>
          )}
          <div className={`quote-block-author__decription ${extendedAuthorInfo ? "author-with-designation" : ""}`}>
            <p>
              <span className="quote-block-author__name">{author.name}</span>
              {extendedAuthorInfo && author.designation && <span>, {author.designation}</span>}
            </p>
          </div>
        </div>
      )}
    </Section>
  );
};

export default QuoteBlock;
